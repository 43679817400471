import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "Navigation",
  },
  {
    id: "mobbers",
    title: "Mobbers",
    type: "collapse",
    icon: <Icon.User size={20} />,
    permissions: ["super-admin", "salt-user"],
    // navLink: "/mobbers",
    children: [
      {
        id: "allMobbers",
        title: "All Mobbers",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super-admin", "salt-user"],
        navLink: "/mobbers",
      },
      {
        id: "inReviewMobbers",
        title: "In Review",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super-admin"],
        navLink: "/mobbers?inReview=1",
      },
    ],
  },
  {
    id: "profile",
    title: "My Profile",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["super-admin", "salt-user", "client", "freelancer"],
    navLink: "/profile",
    onClick: () => window.location.reload(),
  },
  {
    id: "projects",
    title: "Projects",
    type: "collapse",
    permissions: ["super-admin", "salt-user", "client", "freelancer"],
    icon: <Icon.Folder size={20} />,
    children: [
      {
        id: "allProjects",
        title: "All Projects",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super-admin", "salt-user", "client", "freelancer"],
        navLink: "/pods",
      },
      {
        id: "listView",
        title: "New Project",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["salt-user", "client", "super-admin"],
        navLink: "/pod",
      },
    ],
  },
  {
    id: "accountSettings",
    title: "Account Settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["salt-user", "client", "freelancer", "super-admin"],
    navLink: "/account-settings",
    onClick: () => window.location.reload(),
  },
  {
    id: "companies",
    title: "Companies",
    type: "collapse",
    permissions: ["salt-user", "client", "super-admin"],
    icon: <Icon.Users size={20} />,
    children: [
      {
        id: "allCompanies",
        title: "All Companies",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["salt-user", "client", "super-admin"],
        navLink: "/companies",
      },
      {
        id: "listView",
        title: "New Company",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["salt-user", "client", "super-admin"],
        navLink: "/company/new",
      },
    ],
  },
  {
    id: "proposals",
    title: "Proposals",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["salt-user", "freelancer", "super-admin"],
    navLink: "/proposals",
    // onClick: () => window.location.reload(),
  },
  {
    id: "addNDA",
    title: "Add NDA",
    type: "item",
    icon: <Icon.Bookmark size={12} />,
    permissions: ["super-admin"],
    navLink: "/nda",
  },
  // {
  //   id: "quotients",
  //   title: "Quotients",
  //   type: "item",
  //   icon: <Icon.Settings size={20} />,
  //   permissions: ["salt-user", "client", "super-admin"],
  //   navLink: "/quotients",
  //   // onClick: () => window.location.reload(),
  // },
  {
    id: "quotes",
    title: "Quotes",
    type: "collapse",
    icon: <Icon.FileText size={20} />,
    permissions: ["salt-user", "client", "super-admin"],
    children: [
      {
        id: "createNewQuote",
        title: "Create a new quote",
        type: "item",
        colorClass: "quoteChild",
        // icon: <Icon.Circle size={12} />,
        permissions: ["client"],
        navLink: "/quotients/new",
      },
      {
        id: "viewQuoteChat",
        title: "View Chat",
        type: "item",
        colorClass: "quoteChild",
        // icon: <Icon.Circle size={12} />,
        permissions: ["salt-user", "client", "super-admin"],
        navLink: "/quotients",
      },
    ],
  },
];

export default navigationConfig;
