import blankUserImg from "../../../assets/img/profile/blank-profile-picture.png"
import cloudfront from "../../../utility/helpers/cloudfront"

export const login = (state = { userRole: null }, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload }
    }

    case "LOGIN_WITH_JWT": {
      return { ...state,
        currentUser: {...action.payload, img: action.payload.profilePhoto? action.payload.profilePhoto.formats.thumbnail.url : blankUserImg },
        userRole: action.payload.role.name
      }
    }
    case "UPDATE_USER": {
      const updatedUser = {...action.payload, img: action.payload.profilePhoto? action.payload.profilePhoto.formats.thumbnail.url : blankUserImg }
      const currentUser = state.currentUser.id === action.payload.id? updatedUser : state.currentUser;
      return { ...state,
        updatedUser,
        currentUser
      }
    }
    case "UPDATE_PASSWORD": {
      return { ...state,
        currentUser: {...action.payload, img: action.payload.profilePhoto? action.payload.profilePhoto.formats.thumbnail.url : blankUserImg }
      }
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    default: {
      return state
    }
  }
}
