import { 
  GET_COMPANY_DATA_START, 
  GET_COMPANY_DATA_SUCCESS,
  GET_COMPANY_DATA_FAIL,
  GET_COMPANY_ID_FAIL, 
  GET_COMPANY_ID_START, 
  GET_COMPANY_ID_SUCCESS 
} from "../../actions/pod";

const initialState = {
  pod: null,
  error: false,
  loading: true,
  mobberError: false,
  companyId : undefined,
  companyData : [],
  companyDataLoading: false,
};

const pod = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_POD":
      return { ...state, pod: action.payload, loading: false, error: false };

    case "GET_POD":
      return { ...state, pod: action.pod, loading: false, error: false };

    case "GET_POD_ERROR":
      return { ...state, pod: null, loading: false, error: true };

    case "GET_POD_LOADING":
      return { ...state, pod: null, loading: true, error: false };

    case "ADD_MOBBERS_DATA":
      return { ...state, pod: { ...state.pod, mobbers: [...action.mobbers] } };

    case "ADD_MOBBERS_ERROR":
      return { ...state, mobberError: true };
    case GET_COMPANY_ID_START:
      return {...state}
    case GET_COMPANY_ID_SUCCESS:
      return {...state, companyId : action.companyId}
    case GET_COMPANY_ID_FAIL:
      return {...state}
    case GET_COMPANY_DATA_START:
      return {...state,companyDataLoading:true }
    case GET_COMPANY_DATA_SUCCESS:
      return {...state, companyData: action.companyData, companyDataLoading:false,}
    case GET_COMPANY_DATA_FAIL:
      return {...state, companyDataLoading:false}

    default:
      return state;
  }
};

export default pod;
