import { history } from "../../../history";
import api from "../../../utility/helpers/api";
import axios from "../../../utility/helpers/interceptor";

// actions
export const GET_QUOTIENTS_START = "GET_QUOTIENTS_START";
export const GET_QUOTIENTS_SUCCESS = "GET_QUOTIENTS_SUCCESS";
export const GET_QUOTIENTS_FAIL = "GET_QUOTIENTS_FAIL";

export const GET_QUOTIENT_START = "GET_QUOTIENT_START";
export const GET_QUOTIENT_SUCCESS = "GET_QUOTIENT_SUCCESS";
export const GET_QUOTIENT_FAIL = "GET_QUOTIENT_FAIL";

export const GET_QUOTIENT_CHAT_START = "GET_QUOTIENT_CHAT_START";
export const GET_QUOTIENT_CHAT_SUCCESS = "GET_QUOTIENT_CHAT_SUCCESS";
export const GET_QUOTIENT_CHAT_FAIL = "GET_QUOTIENT_CHAT_FAIL";
export const SAVE_QUOTIENT_CHAT_START = "SAVE_QUOTIENT_CHAT_START";
export const SAVE_QUOTIENT_CHAT_SUCCESS = "SAVE_QUOTIENT_CHAT_SUCCESS";
export const SAVE_QUOTIENT_CHAT_FAIL = "SAVE_QUOTIENT_CHAT_FAIL";

export const UPLOAD_PDF_START = "UPLOAD_PDF_START";
export const UPLOAD_PDF_FAIL = "UPLOAD_PDF_FAIL";
export const UPLOAD_PDF_SUCCESS = "UPLOAD_PDF_SUCCESS";

export const ADD_QUOTIENT_START = "ADD_QUOTIENT_START";
export const ADD_QUOTIENT_SUCCESS = "ADD_QUOTIENT_SUCCESS";
export const ADD_QUOTIENT_FAIL = "ADD_QUOTIENT_FAIL";

export const APPROVE_PDF_START = "APPROVE_PDF_START";
export const APPROVE_PDF_FAIL = "APPROVE_PDF_FAIL";
export const APPROVE_PDF_SUCCESS = "APPROVE_PDF_SUCCESS";

export const REQUEST_FINAL_QUOTE_STATUS_START =
  "REQUEST_FINAL_QUOTE_STATUS_START";
export const REQUEST_FINAL_QUOTE_STATUS_SUCCESS =
  "REQUEST_FINAL_QUOTE_STATUS_SUCCESS";
export const REQUEST_FINAL_QUOTE_STATUS_FAIL =
  "REQUEST_FINAL_QUOTE_STATUS_FAIL";

export const MODAL_OPEN = "MODAL_OPEN";
// Action Types
// Getting all Quotients
const getQuotientsStart = () => ({
  type: GET_QUOTIENTS_START,
});
const getQuotientsSuccess = (quotients) => ({
  type: GET_QUOTIENTS_SUCCESS,
  quotients,
});
const getQuotientsFail = (listError) => ({
  type: GET_QUOTIENTS_FAIL,
  listError,
});

export const getQuotients = (clientId) => {
  return async (dispatch) => {
    let url = `${api}/quotients`;

    if (clientId) {
      url = `${url}?client=${clientId}`;
    }
    dispatch(getQuotientsStart());
    try {
      const res = await axios.get(url);

      dispatch(getQuotientsSuccess(res.data));
    } catch (e) {
      dispatch(getQuotientsFail(e.response.data));
    }
  };
};
// Upload quotient pdf
const uploadPdfStart = () => ({
  type: UPLOAD_PDF_START,
});
// const uploadPdfSuccess = () => ({
//   type: UPLOAD_PDF_SUCCESS,
// });
const uploadPdfFail = (newError) => ({
  type: UPLOAD_PDF_FAIL,
  newError,
});

export const uploadQuotientPdf = (file, data) => {
  return async (dispatch) => {
    const url = `${api}/upload`;
    dispatch(uploadPdfStart());

    try {
      const res = await axios.post(url, file, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      });
      if (res.status === 200) {
        const pdf = res.data[0];
        // to set pdf is initial pdf
        pdf.status = 0;
        if (data.quotientChat) {
          delete data.quotientChat;
          dispatch(saveQuotientChat({ ...data, pdf, quotient:data.quotient, action: "upload" }));
        } else {
          data.dueDate = data.dueDate[0];
          dispatch(addQuotient({ pdf, ...data }));
        }
      }
    } catch (e) {
      dispatch(uploadPdfFail(e.response.data));
    }
  };
};
// Add New Quotient
const addQuotientStart = () => ({
  type: ADD_QUOTIENT_START,
});
const addQuotientSuccess = () => ({
  type: ADD_QUOTIENT_SUCCESS,
});
const addQuotientFail = (newError) => ({
  type: ADD_QUOTIENT_FAIL,
  newError,
});

export const addQuotient = (data) => {
  return async (dispatch) => {
    const url = `${api}/quotients`;
    dispatch(addQuotientStart());
    try {
      const res = await axios.post(url, data);
      if (res.status === 200) {
        dispatch(addQuotientSuccess());
        history.push(`/quotients`);
      }
    } catch (e) {
      dispatch(addQuotientFail(e.response.data));
    }
  };
};

export const updateQuotient = (data) => {
  return async (dispatch) => {
    const { status, id } = data;
    const url = `${api}/quotients/${id}`;
    dispatch(addQuotientStart());
    try {
      const res = await axios.put(url, { status });
      if (res.status === 200) {
        dispatch(addQuotientSuccess());
        history.push(`/quotients/${id}`);
      }
    } catch (e) {
      dispatch(addQuotientFail(e.response.data));
    }
  }
}
// Get Quotient by Id
const getQuotientStart = () => ({
  type: GET_QUOTIENT_START,
});
const getQuotientSuccess = (quotient) => ({
  type: GET_QUOTIENT_SUCCESS,
  quotient,
});
const getQuotientFail = (error) => ({
  type: GET_QUOTIENT_FAIL,
  error,
});

export const getQuotient = (quotientId) => {
  return async (dispatch) => {
    const url = `${api}/quotients/${quotientId}`;
    dispatch(getQuotientStart());
    try {
      const res = await axios.get(url);
      dispatch(getQuotientSuccess(res.data));
    } catch (e) {
      history.push("/quotients");
      dispatch(getQuotientFail(e.response.data));
    }
  };
};

// Get Quotient by Id test
const approveQuotientStart = () => ({
  type: APPROVE_PDF_START,
});
const approveQuotientSuccess = (quotient) => ({
  type: APPROVE_PDF_SUCCESS,
  quotient,
});
const approveQuotientFail = (error) => ({
  type: APPROVE_PDF_FAIL,
  error,
});
export const approveQuotient = (data) => {
  const { remarks, quotientID, id } = data;
  return async (dispatch) => {
    const url = `${api}/quotient-chats/pdfApproval/${id}`;
    dispatch(approveQuotientStart());
    try {
      const res = await axios.put(url, { remarks, quotientID });
      dispatch(approveQuotientSuccess(res.data));
      dispatch(getQuotientChats(quotientID));
    } catch (e) {
      dispatch(approveQuotientFail(e.response.data));
    }
  };
};
// Get quotient chats
const getQuotientChatStart = () => ({
  type: GET_QUOTIENT_CHAT_START,
});
const getQuotientChatSuccess = (chats) => ({
  type: GET_QUOTIENT_CHAT_SUCCESS,
  chats,
});
const getQuotientChatFail = (chatError) => ({
  type: GET_QUOTIENT_CHAT_FAIL,
  chatError,
});

export const getQuotientChats = (quotientId) => {
  return async (dispatch) => {
    const url = `${api}/quotient-chats?quotient=${quotientId}&_sort=created_at:DESC`;
    dispatch(getQuotientChatStart());
    try {
      const res = await axios.get(url);
      const sorted = res.data;
      // sorting the chat
      sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      dispatch(getQuotientChatSuccess(sorted));
    } catch (e) {
      history.push("/quotients");
      dispatch(getQuotientChatFail(e.response.data));
    }
  };
};

// Quotient message sent
const saveQuotientChatStart = () => ({
  type: SAVE_QUOTIENT_CHAT_START,
});
const saveQuotientChatSuccess = (chats) => ({
  type: SAVE_QUOTIENT_CHAT_SUCCESS,
  chats,
});
const saveQuotientChatFail = (chatError) => ({
  type: SAVE_QUOTIENT_CHAT_FAIL,
  chatError,
});

export const saveQuotientChat = (data) => {
  return async (dispatch) => {
    const url = `${api}/quotient-chats`;
    dispatch(saveQuotientChatStart());
    try {
      const res = await axios.post(url, data);
      if (res.status === 200) {
        dispatch(saveQuotientChatSuccess());
        dispatch(getQuotientChats(data.quotient));
      }
    } catch (e) {
      dispatch(saveQuotientChatFail(e.response.data));
    }
  };
};

const reqFinalQuoteStatusStart = () => ({
  type: REQUEST_FINAL_QUOTE_STATUS_START,
});
const reqFinalQuoteStatusSuccess = () => ({
  type: REQUEST_FINAL_QUOTE_STATUS_SUCCESS,
});
const reqFinalQuoteStatusFail = (finalQuoteError) => ({
  type: REQUEST_FINAL_QUOTE_STATUS_SUCCESS,
  finalQuoteError,
});
export const reqFinalQuoteStatus = (data) => {
  return async (dispatch) => {
    const { status, id } = data;
    const url = `${api}/quotients/${id}`;
    dispatch(reqFinalQuoteStatusStart());
    try {
      // axios.put()
      const res = await axios.put(url, { status });
      if (res.status === 200) {
        dispatch(reqFinalQuoteStatusSuccess());
      }
    } catch (e) {
      dispatch(reqFinalQuoteStatusFail(e.response.data));
    }
  };
};
const toggleModal = (confirmModalOpenState, selectedPdfItemId, selectedPdfItemName) => ({
  type: MODAL_OPEN,
  confirmModalOpenState,
  selectedPdfItemId,
  selectedPdfItemName
});
export const toggleModalOpen = (confirmModalOpen, selectedPdfItemId=null, selectedPdfItemName=null) => {
  return async (dispatch) => {
    dispatch(toggleModal(confirmModalOpen, selectedPdfItemId, selectedPdfItemName));
  };
};
// files path /upload
// submit path /quotients{client & pdf(upload file)}

/***
 
 * 
 * 
 * 
 * 
 * 
 * 
 */
