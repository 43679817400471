import React from "react";
import { Navbar } from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
// import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import {
	logoutWithJWT,
	// logoutWithFirebase
} from "../../../redux/actions/auth/loginActions";
import { setMessages } from "../../../redux/actions/messages";
import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
import blankUserImg from "../../../assets/img/profile/blank-profile-picture.png";
import roleNames from "../../../utility/data/roles";

const ThemeNavbar = (props) => {
	const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
	const navbarTypes = ["floating", "static", "sticky", "hidden"];
	const userRole = props.user.role ? props.user.role.name : "";

	return (
		<React.Fragment>
			<div className="content-overlay" />
			<div className="header-navbar-shadow" />
			<Navbar
				className={classnames(
					"header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
					{
						"navbar-light":
							props.navbarColor === "default" ||
							!colorsArr.includes(props.navbarColor),
						"navbar-dark": colorsArr.includes(props.navbarColor),
						"bg-primary":
							props.navbarColor === "primary" && props.navbarType !== "static",
						"bg-danger":
							props.navbarColor === "danger" && props.navbarType !== "static",
						"bg-success":
							props.navbarColor === "success" && props.navbarType !== "static",
						"bg-info":
							props.navbarColor === "info" && props.navbarType !== "static",
						"bg-warning":
							props.navbarColor === "warning" && props.navbarType !== "static",
						"bg-dark":
							props.navbarColor === "dark" && props.navbarType !== "static",
						"d-none": props.navbarType === "hidden" && !props.horizontal,
						"floating-nav":
							(props.navbarType === "floating" && !props.horizontal) ||
							(!navbarTypes.includes(props.navbarType) && !props.horizontal),
						"navbar-static-top":
							props.navbarType === "static" && !props.horizontal,
						"fixed-top": props.navbarType === "sticky" || props.horizontal,
						scrolling: props.horizontal && props.scrolling,
					}
				)}
			>
				<div className="navbar-wrapper">
					<div className="navbar-container content">
						<div
							className="navbar-collapse d-flex justify-content-between align-items-center"
							id="navbar-mobile"
						>
							<div className="bookmark-wrapper">
								<NavbarBookmarks
									sidebarVisibility={props.sidebarVisibility}
									handleAppOverlay={props.handleAppOverlay}
								/>
							</div>
							{props.horizontal ? (
								<div className="logo d-flex align-items-center">
									<div className="brand-logo mr-50"></div>
								</div>
							) : null}
							<NavbarUser
								handleAppOverlay={props.handleAppOverlay}
								changeCurrentLang={props.changeCurrentLang}
								// userName={<UserName userdata={props.user} {...props} />}
								userName={
									props.user.firstName
										? `${props.user.firstName} ${props.user.lastName}`
										: props.user.email
								}
								userImg={
									props.user.profilePhoto
										? props.user.profilePhoto.url
										: props.user.img
								}
								userRole={roleNames[userRole]}
								// loggedInWith={
								//   props.user !== undefined &&
								//   props.user.login.values !== undefined
								//     ? props.user.login.values.loggedInWith
								//     : null
								// }
								logoutWithJWT={props.logoutWithJWT}
								setMessages={props.setMessages}
								// logoutWithFirebase={props.logoutWithFirebase}
							/>
						</div>
					</div>
				</div>
			</Navbar>
		</React.Fragment>
	);
};
const mapStateToProps = (state) => {
	const user = state.auth.login.currentUser;
	return {
		user: user ? user : { img: blankUserImg },
	};
};

export default connect(mapStateToProps, {
	logoutWithJWT,
	setMessages,
	// logoutWithFirebase,
	// useAuth0
})(ThemeNavbar);
