const initialState = {
  cards: [],
  addError: false,
  updateError: false,
  deleteError: false,
  loading: false,
  cardActivityLoading: false,
  selectedCard: {},
  getCardActivityError: false,
  allKanbanUsers: [],
  cardActivities: [],
  cardLayout: {
    1: [],
    2: [],
    3: [],
    4: [],
  },
  pod: null,
  podLoading: false,
  podError: false,
  allLanes: {
    lanes: [
      {
        id: "1",
        title: "To Do",
        cards: [],
      },
      {
        id: "2",
        title: "In Progress",
        cards: [],
      },
      {
        id: "3",
        title: "Review",
        cards: [],
      },
      {
        id: "4",
        title: "Done",
        cards: [],
      },
    ],
  },
};

const kanban = (state = initialState, action) => {
  switch (action.type) {
    case "GET_POD_CARDS":
      let allCards = action.payload.cards;
      let cardLayout = action.payload.cardLayout;
      let allLanes = state.allLanes.lanes;
      console.log("cards that came back", action.payload);

      let allIds = ["1", "2", "3", "4"];

      allIds.map((a) => {
        cardLayout[a].map((b) => {
          let selectCard = allCards.find((obj) => b == obj.id);
          allLanes.find((obj) => obj.id == a).cards.push(selectCard);
        });
      });

      // allCards.map((a) => {
      //   allLanes.find((obj) =>UPDATE_CARD obj.id == a.laneId).cards.push(a);
      // });

      return {
        ...state,
        pod: action.payload,
        cards: [...action.payload.cards],
        cardLayout: action.payload.cardLayout,
        allKanbanUsers: [action.payload.user, ...action.payload.mobbers],
        podLoading: false,
        podError: false,
      };

    case "GET_POD_CARDS_LOADING":
      return {
        ...state,
        podLoading: true,
        podError: false,
      };

    case "UPDATE_LAYOUT":
      return {
        ...state,
        cardLayout: action.payload,
      };

    case "GET_POD_CARDS_LOADING":
      return {
        ...state,
        podLoading: false,
        podError: false,
      };

    case "ADD_CARD":
      let selectedLanes = state.allLanes.lanes.find(
        (a) => a.id === action.payload.laneId
      );
      let allC = [...selectedLanes.cards, action.payload];
      let updateLanes = { ...selectedLanes, cards: allC };

      console.log("add card action.payload", action.payload);

      let updateCardLayout = state.cardLayout[action.payload.laneId].splice(
        state.cardLayout[action.payload.laneId].length + 1,
        0,
        action.payload.id
      );

      return {
        ...state,
        cards: [...state.cards, action.payload],
        allLanes: {
          lanes: state.allLanes.lanes.map((a) =>
            a.id === action.payload.laneId ? { ...updateLanes } : a
          ),
        },
        loading: false,
      };

    case "UPDATE_CARD":
      let allL = state.allLanes.lanes;
      let cardL = state.cardLayout;
      let allCs = state.cards;

      console.log("state.cards", state.cards);

      allL = [
        {
          id: "1",
          title: "To Do",
          cards: [],
        },
        {
          id: "2",
          title: "In Progress",
          cards: [],
        },
        {
          id: "3",
          title: "Review",
          cards: [],
        },
        {
          id: "4",
          title: "Done",
          cards: [],
        },
      ];

      // update the card inside cards and continue with the flow from above
      //

      if (Object.keys(action.payload.movedCard).length !== 0) {
        let { fromLaneId, toLaneId, cardId, index } = action.payload.movedCard;

        // let laneCard = allL[fromLaneId - 1].cards;
        // let getCard = laneCard.find((a) => a.id === cardId);
        // let filterCard = laneCard.filter((a) => a.id !== cardId);
        if (fromLaneId !== toLaneId) {
          let remLay = cardL[fromLaneId].filter((a) => a !== cardId);
          let addLay = cardL[toLaneId].splice(index, 0, cardId);
          cardL = { ...cardL, [fromLaneId]: remLay };
        } else {
          let curPos = cardL[fromLaneId].indexOf(cardId);
          cardL[fromLaneId].splice(curPos, 1);
          let addLay = cardL[toLaneId].splice(index, 0, cardId);
        }

        // let addLaneCard = allL[toLaneId - 1].cards;
        // let addCard = addLaneCard.splice(index, 0, getCard);
      }

      let alIds = ["1", "2", "3", "4"];

      allCs = allCs.filter((a) => a.id !== action.payload.id);

      let assignee = action.payload.assignee?.id
        ? action.payload.assignee?.id
        : null;

      allCs = [...allCs, { ...action.payload, assignee }];

      alIds.map((a) => {
        cardL[a].map((b) => {
          let selectCard = allCs.find((obj) => b == obj.id);
          allL.find((obj) => obj.id == a).cards.push(selectCard);
        });
      });

      state.allLanes.lanes = allL;

      console.log("allL", allL);

      // let selectedLane = state.allLanes.lanes.find(
      //   (a) => a.id === action.payload.laneId
      // );

      // let assignee;
      // if (action.payload.assignee) {
      //   assignee = action.payload.assignee.id;
      // }

      // let card;
      // if (selectedLane.cards.some((a) => a.id === action.payload.id)) {
      //   card = selectedLane.cards.map((obj) =>
      //     obj.id == action.payload.id
      //       ? {
      //           ...action.payload,
      //           assignee: assignee,
      //         }
      //       : obj
      //   );
      // } else {
      //   card = [
      //     ...selectedLane.cards,
      //     { ...action.payload, assignee: assignee },
      //   ];
      //   fromLaneId = action.payload.fromLaneId;
      // }

      return {
        ...state,
        cardLayout: cardL,
        cards: [...allCs],
        allLanes: {
          lanes: allL,
        },
        loading: false,
      };

    case "DELETE_CARD":
      let sl = state.allLanes.lanes.find((a) => a.id === action.payload.laneId);

      let cardRemain = sl.cards.filter(
        (obj) => obj.id !== action.payload.cardId
      );

      let removedCard = state.cardLayout[action.payload.laneId].filter(
        (a) => a !== action.payload.cardId
      );

      return {
        ...state,
        cardLayout: {
          ...state.cardLayout,
          [action.payload.laneId]: removedCard,
        },
        cards: state.cards.filter((a) => a.id !== action.payload.cardId),
        allLanes: {
          lanes: state.allLanes.lanes.map((a) =>
            a.id === action.payload.laneId ? { ...sl, cards: cardRemain } : a
          ),
        },
        loading: false,
      };

    case "GET_CARD_ACTIVITIES":
      return {
        ...state,
        cardActivities: [...action.payload],
        cardActivityError: false,
        getCardActivityLoading: false,
      };

    case "GET_CARD_ACTIVITIES_LOADING":
      return { ...state, cardActivityLoading: true };

    case "GET_CARD":
      return {
        ...state,
        selectedCard: {
          ...action.payload,
        },
      };

    case "GET_CARD_REMOVE":
      return { ...state, selectedCard: {} };

    case "GET_CARD_ACTIVITIES_ERROR":
      return {
        ...state,
        cardActivityError: true,
        getCardActivityLoading: false,
      };

    case "DELETE_ALL_CARDS":
      return { ...state, cards: [], loading: false };

    case "CARD_LOADING":
      return { ...state, loading: true };

    case "ADD_CARD_ERROR":
      return { ...state, loading: false, addError: true };

    case "UPDATE_CARD_ERROR":
      return {
        ...state,
        loading: false,
        updateError: true,
      };

    case "DELETE_CARD_ERROR":
      return {
        ...state,
        loading: false,
        deleteError: true,
      };

    default:
      return state;
  }
};

export default kanban;
