import React from "react";

export const Logo = ({ mobile }) => (
  <>
    {mobile ? (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="180px"
        height="38px"
        viewBox="0 0 1671.156 356.235"
        enable-background="new 0 0 1671.156 356.235"
      >
        <g>
          <path
            fill="white"
            d="M92.191,85.379h62.707l25.665,120.917l66.94-120.917h62.443l-33.073,186.534h-51.595l18.256-103.189l-53.711,103.189
		h-43.393l-16.934-103.718l-18.256,103.718H59.117L92.191,85.379z"
          />
          <path
            fill="white"
            d="M451.771,126.391h-67.47l-5.292,30.957h59.532l-7.144,39.424h-59.532l-6.085,33.867h67.47l-7.144,41.275H306.512
		l33.074-186.534h119.329L451.771,126.391z"
          />
          <path
            fill="white"
            d="M639.364,178.514c-9.525,53.976-54.241,93.399-114.037,93.399h-73.82L484.58,85.379h73.82
		C617.932,85.379,649.154,123.745,639.364,178.514z M529.56,226.405c29.898,0,51.594-17.198,57.15-47.891
		c5.292-30.957-10.319-48.155-40.217-48.155h-17.728l-17.198,96.045H529.56z"
          />
          <path
            fill="white"
            d="M679.053,85.379h51.859l-32.809,186.534h-52.124L679.053,85.379z"
          />
          <path
            fill="white"
            d="M851.036,241.486h-66.147l-15.346,30.428h-54.505L815.846,85.379h59.796l34.926,186.534h-54.77L851.036,241.486z
		 M835.69,141.472l-30.692,60.59h39.953L835.69,141.472z"
          />
          <path
            fill="#00ADAA"
            d="M1013.494,85.379h62.707l25.665,120.917l66.94-120.917h62.443l-33.074,186.534h-51.594l18.256-103.189
		l-53.711,103.189h-43.393L1050.8,168.195l-18.256,103.718H980.42L1013.494,85.379z"
          />
          <path
            fill="#00ADAA"
            d="M1528.388,271.913h-92.341l33.073-186.534h89.431c37.836,0,57.944,17.463,52.652,48.419
		c-3.969,22.49-20.108,37.307-39.158,42.334c21.431,5.027,31.75,23.813,27.781,44.98
		C1594.535,252.599,1568.605,271.913,1528.388,271.913z M1531.298,196.242H1501.4l-5.82,34.132h30.162
		c12.701,0,20.374-5.292,22.49-16.669C1550.085,202.327,1543.999,196.242,1531.298,196.242z M1540.03,126.655h-26.194l-5.82,33.073
		h26.193c12.701,0,20.374-5.556,22.226-16.404C1558.287,132.211,1552.73,126.655,1540.03,126.655z"
          />
          <g>
            <path
              fill="#00ADAA"
              d="M1283.933,217.837c-0.049,0-0.098,0-0.146,0.001c-5.253,0.074-9.313,4.038-9.244,9.024
			c0.07,4.987,4.267,8.854,9.493,8.762c2.542-0.036,4.897-0.995,6.633-2.702c1.717-1.689,2.645-3.934,2.611-6.322
			c-0.033-2.388-1.023-4.607-2.787-6.247C1288.744,218.727,1286.42,217.837,1283.933,217.837z"
            />
            <path
              fill="#00ADAA"
              d="M1282.109,136.685c-1.297,0.018-2.49,0.498-3.357,1.351c-0.85,0.835-1.308,1.955-1.291,3.154
			c0.035,2.489,2.116,4.446,4.773,4.373c2.641-0.037,4.683-2.016,4.648-4.505C1286.847,138.568,1284.737,136.629,1282.109,136.685z"
            />
            <path
              fill="#00ADAA"
              d="M1393.742,144.383c0.884-0.012,1.688-0.332,2.267-0.9c0.56-0.551,0.862-1.296,0.851-2.098
			c-0.023-1.667-1.372-2.91-3.146-2.91c-0.018,0-0.035,0-0.054,0c-1.8,0.025-3.139,1.314-3.115,2.999
			c0.011,0.802,0.334,1.538,0.909,2.073C1392.047,144.099,1392.873,144.415,1393.742,144.383z"
            />
            <path
              fill="#00ADAA"
              d="M1329.899,168.188c-0.074,0-0.146,0-0.221,0.001c-7.866,0.11-13.945,6.06-13.84,13.544
			c0.05,3.578,1.54,6.908,4.196,9.377c2.673,2.486,6.189,3.839,10.017,3.773c7.865-0.11,13.944-6.06,13.84-13.544
			C1343.787,173.926,1337.659,168.188,1329.899,168.188z"
            />
            <path
              fill="#00ADAA"
              d="M1348.996,82.469c-65.088,0-110.068,46.303-110.068,113.243c0,46.303,31.221,78.053,79.112,78.053
			c65.882,0,110.862-47.361,110.862-113.772C1428.901,113.69,1397.945,82.469,1348.996,82.469z M1401.007,218.464
			c-3.762,3.7-8.772,5.778-14.11,5.854c-0.1,0.001-0.2,0.002-0.299,0.002c-10.966,0-19.956-8.48-20.104-19.036
			c-0.003-0.251,0-0.509,0.009-0.778l-16.289-6.992c-4.95,5.802-12.172,9.168-19.994,9.278c-4.932,0.048-9.659-1.154-13.831-3.547
			l-13.579,13.355c1.778,3.02,2.738,6.393,2.786,9.828c0.161,11.47-9.436,20.938-21.393,21.105
			c-0.106,0.001-0.214,0.002-0.321,0.002c-5.635,0-10.969-2.08-15.053-5.877c-4.18-3.886-6.524-9.079-6.602-14.622
			c-0.078-5.543,2.12-10.8,6.189-14.802c4.051-3.984,9.45-6.223,15.203-6.303c3.531-0.068,7.095,0.788,10.328,2.429l13.627-13.402
			c-2.59-3.934-3.986-8.419-4.051-13.052c-0.044-3.153,0.503-6.21,1.63-9.103l-16.875-16.414c-1.852,0.689-3.825,1.053-5.877,1.082
			c-4.526,0.061-8.792-1.576-12.062-4.616c-3.288-3.057-5.133-7.139-5.194-11.492c-0.126-9.014,7.409-16.454,16.797-16.586
			c4.538-0.061,8.792,1.576,12.062,4.616c3.288,3.057,5.133,7.138,5.194,11.492c0.036,2.59-0.568,5.14-1.755,7.444l14.754,14.539
			c4.781-4.168,10.874-6.497,17.313-6.587c1.361-0.021,2.803,0.091,4.359,0.337l6.714-17.55c-5.441-3.202-8.819-8.826-8.905-14.973
			c-0.138-9.832,8.084-17.949,18.328-18.093c0.092-0.001,0.185-0.002,0.275-0.002c4.823,0,9.391,1.783,12.892,5.038
			c3.585,3.333,5.597,7.785,5.663,12.536c0.126,9.002-6.856,16.702-16.061,17.921l-7.435,19.634
			c1.863,1.305,3.546,2.854,5.024,4.623l28.157-21.574c-0.187-0.885-0.283-1.72-0.295-2.53c-0.114-8.195,6.733-14.96,15.265-15.08
			c4.068-0.05,7.985,1.433,10.958,4.197c2.991,2.781,4.669,6.492,4.725,10.449s-1.518,7.713-4.429,10.577
			c-2.895,2.846-6.742,4.445-10.836,4.502c-3.042,0.047-5.909-0.745-8.44-2.284l-29.784,22.554c0.328,1.538,0.5,3.054,0.521,4.605
			c0.026,1.875-0.165,3.744-0.569,5.57l15.026,6.562c3.761-4.704,9.536-7.527,15.689-7.614c5.32-0.086,10.405,1.862,14.27,5.455
			c3.883,3.61,6.061,8.432,6.133,13.579C1406.829,209.865,1404.787,214.746,1401.007,218.464z"
            />
            <path
              fill="#00ADAA"
              d="M1350.34,129.757c1.713-0.024,3.293-0.664,4.449-1.801c1.139-1.12,1.753-2.615,1.73-4.21
			s-0.679-3.072-1.848-4.16c-1.188-1.104-2.819-1.687-4.498-1.676c-1.713,0.024-3.293,0.664-4.449,1.801
			c-1.139,1.12-1.753,2.615-1.73,4.21s0.679,3.072,1.848,4.16C1347.031,129.187,1348.638,129.78,1350.34,129.757z"
            />
            <path
              fill="#00ADAA"
              d="M1386.643,197.592c-0.041,0-0.081,0-0.123,0.001c-2.127,0.03-4.095,0.829-5.54,2.251
			c-1.429,1.404-2.199,3.275-2.171,5.267c0.027,1.992,0.851,3.839,2.317,5.203c1.485,1.381,3.488,2.117,5.602,2.095
			c2.128-0.03,4.096-0.829,5.541-2.251c1.429-1.404,2.199-3.274,2.171-5.267c-0.027-1.992-0.851-3.839-2.317-5.203
			C1390.666,198.333,1388.724,197.592,1386.643,197.592z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    ) : (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="180px"
        height="38px"
        viewBox="0 0 1671.156 356.235"
        enable-background="new 0 0 1671.156 356.235"
      >
        <g>
          <path
            fill="white"
            d="M92.191,85.379h62.707l25.665,120.917l66.94-120.917h62.443l-33.073,186.534h-51.595l18.256-103.189l-53.711,103.189
		h-43.393l-16.934-103.718l-18.256,103.718H59.117L92.191,85.379z"
          />
          <path
            fill="white"
            d="M451.771,126.391h-67.47l-5.292,30.957h59.532l-7.144,39.424h-59.532l-6.085,33.867h67.47l-7.144,41.275H306.512
		l33.074-186.534h119.329L451.771,126.391z"
          />
          <path
            fill="white"
            d="M639.364,178.514c-9.525,53.976-54.241,93.399-114.037,93.399h-73.82L484.58,85.379h73.82
		C617.932,85.379,649.154,123.745,639.364,178.514z M529.56,226.405c29.898,0,51.594-17.198,57.15-47.891
		c5.292-30.957-10.319-48.155-40.217-48.155h-17.728l-17.198,96.045H529.56z"
          />
          <path
            fill="white"
            d="M679.053,85.379h51.859l-32.809,186.534h-52.124L679.053,85.379z"
          />
          <path
            fill="white"
            d="M851.036,241.486h-66.147l-15.346,30.428h-54.505L815.846,85.379h59.796l34.926,186.534h-54.77L851.036,241.486z
		 M835.69,141.472l-30.692,60.59h39.953L835.69,141.472z"
          />
          <path
            fill="#00ADAA"
            d="M1013.494,85.379h62.707l25.665,120.917l66.94-120.917h62.443l-33.074,186.534h-51.594l18.256-103.189
		l-53.711,103.189h-43.393L1050.8,168.195l-18.256,103.718H980.42L1013.494,85.379z"
          />
          <path
            fill="#00ADAA"
            d="M1528.388,271.913h-92.341l33.073-186.534h89.431c37.836,0,57.944,17.463,52.652,48.419
		c-3.969,22.49-20.108,37.307-39.158,42.334c21.431,5.027,31.75,23.813,27.781,44.98
		C1594.535,252.599,1568.605,271.913,1528.388,271.913z M1531.298,196.242H1501.4l-5.82,34.132h30.162
		c12.701,0,20.374-5.292,22.49-16.669C1550.085,202.327,1543.999,196.242,1531.298,196.242z M1540.03,126.655h-26.194l-5.82,33.073
		h26.193c12.701,0,20.374-5.556,22.226-16.404C1558.287,132.211,1552.73,126.655,1540.03,126.655z"
          />
          <g>
            <path
              fill="#00ADAA"
              d="M1283.933,217.837c-0.049,0-0.098,0-0.146,0.001c-5.253,0.074-9.313,4.038-9.244,9.024
			c0.07,4.987,4.267,8.854,9.493,8.762c2.542-0.036,4.897-0.995,6.633-2.702c1.717-1.689,2.645-3.934,2.611-6.322
			c-0.033-2.388-1.023-4.607-2.787-6.247C1288.744,218.727,1286.42,217.837,1283.933,217.837z"
            />
            <path
              fill="#00ADAA"
              d="M1282.109,136.685c-1.297,0.018-2.49,0.498-3.357,1.351c-0.85,0.835-1.308,1.955-1.291,3.154
			c0.035,2.489,2.116,4.446,4.773,4.373c2.641-0.037,4.683-2.016,4.648-4.505C1286.847,138.568,1284.737,136.629,1282.109,136.685z"
            />
            <path
              fill="#00ADAA"
              d="M1393.742,144.383c0.884-0.012,1.688-0.332,2.267-0.9c0.56-0.551,0.862-1.296,0.851-2.098
			c-0.023-1.667-1.372-2.91-3.146-2.91c-0.018,0-0.035,0-0.054,0c-1.8,0.025-3.139,1.314-3.115,2.999
			c0.011,0.802,0.334,1.538,0.909,2.073C1392.047,144.099,1392.873,144.415,1393.742,144.383z"
            />
            <path
              fill="#00ADAA"
              d="M1329.899,168.188c-0.074,0-0.146,0-0.221,0.001c-7.866,0.11-13.945,6.06-13.84,13.544
			c0.05,3.578,1.54,6.908,4.196,9.377c2.673,2.486,6.189,3.839,10.017,3.773c7.865-0.11,13.944-6.06,13.84-13.544
			C1343.787,173.926,1337.659,168.188,1329.899,168.188z"
            />
            <path
              fill="#00ADAA"
              d="M1348.996,82.469c-65.088,0-110.068,46.303-110.068,113.243c0,46.303,31.221,78.053,79.112,78.053
			c65.882,0,110.862-47.361,110.862-113.772C1428.901,113.69,1397.945,82.469,1348.996,82.469z M1401.007,218.464
			c-3.762,3.7-8.772,5.778-14.11,5.854c-0.1,0.001-0.2,0.002-0.299,0.002c-10.966,0-19.956-8.48-20.104-19.036
			c-0.003-0.251,0-0.509,0.009-0.778l-16.289-6.992c-4.95,5.802-12.172,9.168-19.994,9.278c-4.932,0.048-9.659-1.154-13.831-3.547
			l-13.579,13.355c1.778,3.02,2.738,6.393,2.786,9.828c0.161,11.47-9.436,20.938-21.393,21.105
			c-0.106,0.001-0.214,0.002-0.321,0.002c-5.635,0-10.969-2.08-15.053-5.877c-4.18-3.886-6.524-9.079-6.602-14.622
			c-0.078-5.543,2.12-10.8,6.189-14.802c4.051-3.984,9.45-6.223,15.203-6.303c3.531-0.068,7.095,0.788,10.328,2.429l13.627-13.402
			c-2.59-3.934-3.986-8.419-4.051-13.052c-0.044-3.153,0.503-6.21,1.63-9.103l-16.875-16.414c-1.852,0.689-3.825,1.053-5.877,1.082
			c-4.526,0.061-8.792-1.576-12.062-4.616c-3.288-3.057-5.133-7.139-5.194-11.492c-0.126-9.014,7.409-16.454,16.797-16.586
			c4.538-0.061,8.792,1.576,12.062,4.616c3.288,3.057,5.133,7.138,5.194,11.492c0.036,2.59-0.568,5.14-1.755,7.444l14.754,14.539
			c4.781-4.168,10.874-6.497,17.313-6.587c1.361-0.021,2.803,0.091,4.359,0.337l6.714-17.55c-5.441-3.202-8.819-8.826-8.905-14.973
			c-0.138-9.832,8.084-17.949,18.328-18.093c0.092-0.001,0.185-0.002,0.275-0.002c4.823,0,9.391,1.783,12.892,5.038
			c3.585,3.333,5.597,7.785,5.663,12.536c0.126,9.002-6.856,16.702-16.061,17.921l-7.435,19.634
			c1.863,1.305,3.546,2.854,5.024,4.623l28.157-21.574c-0.187-0.885-0.283-1.72-0.295-2.53c-0.114-8.195,6.733-14.96,15.265-15.08
			c4.068-0.05,7.985,1.433,10.958,4.197c2.991,2.781,4.669,6.492,4.725,10.449s-1.518,7.713-4.429,10.577
			c-2.895,2.846-6.742,4.445-10.836,4.502c-3.042,0.047-5.909-0.745-8.44-2.284l-29.784,22.554c0.328,1.538,0.5,3.054,0.521,4.605
			c0.026,1.875-0.165,3.744-0.569,5.57l15.026,6.562c3.761-4.704,9.536-7.527,15.689-7.614c5.32-0.086,10.405,1.862,14.27,5.455
			c3.883,3.61,6.061,8.432,6.133,13.579C1406.829,209.865,1404.787,214.746,1401.007,218.464z"
            />
            <path
              fill="#00ADAA"
              d="M1350.34,129.757c1.713-0.024,3.293-0.664,4.449-1.801c1.139-1.12,1.753-2.615,1.73-4.21
			s-0.679-3.072-1.848-4.16c-1.188-1.104-2.819-1.687-4.498-1.676c-1.713,0.024-3.293,0.664-4.449,1.801
			c-1.139,1.12-1.753,2.615-1.73,4.21s0.679,3.072,1.848,4.16C1347.031,129.187,1348.638,129.78,1350.34,129.757z"
            />
            <path
              fill="#00ADAA"
              d="M1386.643,197.592c-0.041,0-0.081,0-0.123,0.001c-2.127,0.03-4.095,0.829-5.54,2.251
			c-1.429,1.404-2.199,3.275-2.171,5.267c0.027,1.992,0.851,3.839,2.317,5.203c1.485,1.381,3.488,2.117,5.602,2.095
			c2.128-0.03,4.096-0.829,5.541-2.251c1.429-1.404,2.199-3.274,2.171-5.267c-0.027-1.992-0.851-3.839-2.317-5.203
			C1390.666,198.333,1388.724,197.592,1386.643,197.592z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )}
  </>
);
