import mock from "../mock"
const response = [
  {
      "id": 1,
      "username": "luke@lukeleveille.com",
      "email": "luke@lukeleveille.com",
      "provider": "local",
      "confirmed": true,
      "blocked": null,
      "role": {
          "id": 5,
          "name": "super-admin",
          "description": "Top level admin",
          "type": "super_admin",
          "created_by": null,
          "updated_by": null
      },
      "about": "I am a full stack web developer",
      "firstName": "Luke",
      "company": null,
      "website": "https://lukeleveille.com",
      "lastName": "Leveille",
      "facebook": null,
      "twitter": null,
      "instagram": null,
      "phone": "+1 (647) 407-4063",
      "address1": "3-142 Close Ave",
      "address2": "Toronto",
      "country": "Canada",
      "state": "Ontario",
      "zip": "M6K2V5",
      "demoReel": null,
      "city": "Toronto",
      "languages": [
          {
              "value": "english",
              "label": "English",
              "color": "#00adaa"
          },
          {
              "value": "french",
              "label": "French",
              "color": "#00adaa"
          }
      ],
      "skills": [
          {
              "value": "editor",
              "label": "Editor",
              "color": "#00adaa"
          },
          {
              "value": "web-design",
              "label": "Web Design",
              "color": "#00adaa"
          }
      ],
      "created_by": null,
      "updated_by": {
          "id": 1,
          "firstName": "Luke",
          "lastName": "Leveille",
          "username": null
      },
      "created_at": "2020-10-03T22:18:33.661Z",
      "updated_at": "2020-10-05T19:57:21.158Z",
      "profilePhoto": {
          "id": 7,
          "name": "bt-profile-pic.jpg",
          "alternativeText": null,
          "caption": null,
          "width": 400,
          "height": 400,
          "formats": {
              "thumbnail": {
                  "name": "thumbnail_bt-profile-pic.jpg",
                  "hash": "thumbnail_bt_profile_pic_a6fe10928b",
                  "ext": ".jpg",
                  "mime": "image/jpeg",
                  "width": 156,
                  "height": 156,
                  "size": 8.03,
                  "path": null,
                  "url": "https://media-mob-strapi-development.s3.us-east-2.amazonaws.com/thumbnail_bt_profile_pic_a6fe10928b.jpg"
              }
          },
          "hash": "bt_profile_pic_a6fe10928b",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "size": 29.19,
          "url": "https://media-mob-strapi-development.s3.us-east-2.amazonaws.com/bt_profile_pic_a6fe10928b.jpg",
          "previewUrl": null,
          "provider": "aws-s3",
          "provider_metadata": null,
          "created_by": null,
          "updated_by": null,
          "created_at": "2020-10-05T12:55:20.311Z",
          "updated_at": "2020-10-05T12:55:20.311Z"
      },
      "coverPhoto": null,
      "portfolio": []
  },
  {
      "id": 2,
      "username": "justin.oheir@gmail.com",
      "email": "justin.oheir@gmail.com",
      "provider": "local",
      "confirmed": true,
      "blocked": null,
      "role": {
          "id": 5,
          "name": "super-admin",
          "description": "Top level admin",
          "type": "super_admin",
          "created_by": null,
          "updated_by": null
      },
      "about": null,
      "firstName": "Justin",
      "company": null,
      "website": "http://justinoheir.com",
      "lastName": "O'Heir",
      "facebook": null,
      "twitter": null,
      "instagram": null,
      "phone": "+1 (905) 716-8268",
      "address1": "65 East Liberty St",
      "address2": "Unit 504",
      "country": "Canada",
      "state": "Ontario",
      "zip": "M6K 3R2",
      "demoReel": null,
      "city": "Toronto",
      "languages": [
          {
              "value": "english",
              "label": "English",
              "color": "#00adaa"
          }
      ],
      "skills": [
          {
              "value": "web-design",
              "label": "Web Design",
              "color": "#00adaa"
          }
      ],
      "created_by": null,
      "updated_by": {
          "id": 1,
          "firstName": "Luke",
          "lastName": "Leveille",
          "username": null
      },
      "created_at": "2020-10-04T15:29:43.035Z",
      "updated_at": "2020-10-05T16:59:27.985Z",
      "profilePhoto": {
          "id": 9,
          "name": "JustinOHeir.png",
          "alternativeText": null,
          "caption": null,
          "width": 500,
          "height": 500,
          "formats": {
              "thumbnail": {
                  "name": "thumbnail_JustinOHeir.png",
                  "hash": "thumbnail_Justin_O_Heir_1bc14468a8",
                  "ext": ".png",
                  "mime": "image/png",
                  "width": 156,
                  "height": 156,
                  "size": 65.6,
                  "path": null,
                  "url": "https://media-mob-strapi-development.s3.us-east-2.amazonaws.com/thumbnail_Justin_O_Heir_1bc14468a8.png"
              }
          },
          "hash": "Justin_O_Heir_1bc14468a8",
          "ext": ".png",
          "mime": "image/png",
          "size": 574.85,
          "url": "https://media-mob-strapi-development.s3.us-east-2.amazonaws.com/Justin_O_Heir_1bc14468a8.png",
          "previewUrl": null,
          "provider": "aws-s3",
          "provider_metadata": null,
          "created_by": null,
          "updated_by": null,
          "created_at": "2020-10-05T13:34:02.414Z",
          "updated_at": "2020-10-05T13:34:02.414Z"
      },
      "coverPhoto": null,
      "portfolio": []
  }
]

const users = [
  {
    id: 268,
    firstName: "Angelo",
    profilePhoto: {
      formats: {
        thumbnail: {
          url: require("../../assets/img/portrait/small/avatar-s-3.jpg")
        }
      }
    },
    email: "angelo@sashington.com",
    lastName: "Sashington",
    role: {
      name: "super-admin"
    },
    rating: 5,
    skills: ["Photoshop", "Premiere"],
    confirmed: true,
    country: "canada",
    department: "sales",
    company: "WinDon Technologies Pvt Ltd",
    mobile: "+65958951757",
    website: "https://rowboat.com/insititious/Angelo",
    languages: ["English", "Arabic"],
    contact_options: ["email", "message", "phone"],
    location: {
      add_line_1: "A-65, Belvedere Streets",
      add_line_2: "",
      post_code: "1868",
      city: "New York",
      state: "New York",
      country: "United States"
    },
    permissions: {
      users: {
        read: true,
        write: false,
        create: false,
        delete: false
      },
      posts: {
        read: true,
        write: true,
        create: true,
        delete: true
      },
      comments: {
        read: true,
        write: false,
        create: true,
        delete: false
      }
    }
  },
  {
    id: 269,
    firstName: "demodulation463",
    avatar: require("../../assets/img/portrait/small/avatar-s-2.jpg"),
    email: "rubi@ortwein.com",
    lastName: "Rubi Ortwein",
    skills: "Camera Operator",
    country: "Unites States",
    role: {
      name: "salt"
    },
    confirmed: false,
    department: "development"
  },
  {
    id: 270,
    firstName: "undivorced341",
    avatar: require("../../assets/img/portrait/small/avatar-s-3.jpg"),
    email: "donnette@charania.com",
    lastName: "Donnette Charania",
    skills: ["Editing", "Premiere"],
    country: "Unites States",
    role: {
      name: "client"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 271,
    firstName: "bumbo426",
    avatar: require("../../assets/img/portrait/small/avatar-s-12.jpg"),
    email: "ardith@duffett.com",
    lastName: "Ardith Duffett",
    country: "Canada",
    role: {
      name: "freelancer"
    },
    confirmed: false,
    department: "sales"
  },
  {
    id: 272,
    firstName: "ectodactylism214",
    avatar: require("../../assets/img/portrait/small/avatar-s-16.jpg"),
    email: "antone@berman.com",
    lastName: "Antone Berman",
    country: "Canada",
    role: {
      name: "salt"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 273,
    firstName: "panathenaic825",
    avatar: require("../../assets/img/portrait/small/avatar-s-18.jpg"),
    email: "maryann@gour.com",
    lastName: "Maryann Gour",
    country: "Canada",
    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 274,
    firstName: "reptilious612",
    avatar: require("../../assets/img/portrait/small/avatar-s-7.jpg"),
    email: "holli@vanduyne.com",
    lastName: "Holli Vanduyne",
    country: "Canada",
    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 275,
    firstName: "candid910",
    avatar: require("../../assets/img/portrait/small/avatar-s-26.jpg"),
    email: "juanita@sartoris.com",
    lastName: "Juanita Sartoris",
    country: "Canada",
    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "management"
  },
  {
    id: 276,
    firstName: "ferrotungsten928",
    avatar: require("../../assets/img/portrait/small/avatar-s-20.jpg"),
    email: "lia@morga.com",
    lastName: "Lia Morga",
    country: "Canada",
    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "development"
  },
  {
    id: 277,
    firstName: "fibered345",
    avatar: require("../../assets/img/portrait/small/avatar-s-14.jpg"),
    email: "theo@quatrevingt.com",
    lastName: "Theo Quatrevingt",
    country: "Canada",
    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 278,
    firstName: "nonenclosure246",
    avatar: require("../../assets/img/portrait/small/avatar-s-10.jpg"),
    email: "lynwood@flud.com",
    lastName: "Lynwood Flud",
    country: "Canada",
    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 279,
    firstName: "uncandied531",
    avatar: require("../../assets/img/portrait/small/avatar-s-13.jpg"),
    email: "kaitlin@kahola.com",
    lastName: "Kaitlin Kahola",
    country: "Canada",
    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 280,
    firstName: "errancy403",
    avatar: require("../../assets/img/portrait/small/avatar-s-4.jpg"),
    email: "alvin@car.com",
    lastName: "Alvin Car",
    country: "Canada",
    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 281,
    firstName: "renillidae759",
    avatar: require("../../assets/img/portrait/small/avatar-s-16.jpg"),
    email: "justin@jacquelin.com",
    lastName: "Justin Jacquelin",
    country: "Canada",
    role: {
      name: "salt"
    },
    confirmed: true,
    department: "management"
  },
  {
    id: 282,
    firstName: "jellylike89",
    avatar: require("../../assets/img/portrait/small/avatar-s-20.jpg"),
    email: "chloe@tague.com",
    lastName: "Canada",
    country: "Pakistan",
    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "development"
  },
  {
    id: 283,
    firstName: "ocular772",
    avatar: require("../../assets/img/portrait/small/avatar-s-6.jpg"),
    email: "zola@tauarez.com",
    lastName: "Canada",
    country: "Dominica",
    role: {
      name: "client"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 284,
    firstName: "oxgang923",
    avatar: require("../../assets/img/portrait/small/avatar-s-11.jpg"),
    email: "wm@cieszynski.com",
    lastName: "Wm Cieszynski",
    country: "UK",
    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 285,
    firstName: "ideationally882",
    avatar: require("../../assets/img/portrait/small/avatar-s-1.jpg"),
    email: "hope@mobus.com",
    lastName: "UK",
    country: "United States of America",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 286,
    firstName: "cynomorphous587",
    avatar: require("../../assets/img/portrait/small/avatar-s-25.jpg"),
    email: "lee@wernimont.com",
    lastName: "UK",
    country: "South Africa",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "development"
  },
  {
    id: 287,
    firstName: "windtight501",
    avatar: require("../../assets/img/portrait/small/avatar-s-3.jpg"),
    email: "myesha@denman.com",
    lastName: "UK",
    country: "Cyprus",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 288,
    firstName: "strongylate147",
    avatar: require("../../assets/img/portrait/small/avatar-s-21.jpg"),
    email: "cornell@roszell.com",
    lastName: "Cornell Roszell",
    country: "UK",
    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "development"
  },
  {
    id: 289,
    firstName: "reblade348",
    avatar: require("../../assets/img/portrait/small/avatar-s-6.jpg"),
    email: "vernon@ogrodowicz.com",
    lastName: "Vernon Ogrodowicz",
    country: "Botswana",
    role: {
      name: "client"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 290,
    firstName: "trapping909",
    avatar: require("../../assets/img/portrait/small/avatar-s-26.jpg"),
    email: "rosy@litza.com",
    lastName: "Rosy Litza",
    country: "Iran",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "development"
  },
  {
    id: 291,
    firstName: "associatedness456",
    avatar: require("../../assets/img/portrait/small/avatar-s-16.jpg"),
    email: "carl@lano.com",
    lastName: "Carl Lano",
    country: "Japan",
    role: {
      name: "salt"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 292,
    firstName: "deformable333",
    avatar: require("../../assets/img/portrait/small/avatar-s-5.jpg"),
    email: "jamika@overlee.com",
    lastName: "Jamika Overlee",
    country: "Colombia",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 293,
    firstName: "abaptiston684",
    avatar: require("../../assets/img/portrait/small/avatar-s-10.jpg"),
    email: "lyle@pytko.com",
    lastName: "Lyle Pytko",
    country: "Somalia",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "management"
  },
  {
    id: 294,
    firstName: "neglector719",
    avatar: require("../../assets/img/portrait/small/avatar-s-20.jpg"),
    email: "latoria@josef.com",
    lastName: "Latoria Josef",
    country: "Lithuania",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "development"
  },
  {
    id: 295,
    firstName: "perameloid596",
    avatar: require("../../assets/img/portrait/small/avatar-s-18.jpg"),
    email: "tennille@draft.com",
    lastName: "Tennille Draft",
    country: "Somalia",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 296,
    firstName: "fetoplacental529",
    avatar: require("../../assets/img/portrait/small/avatar-s-20.jpg"),
    email: "bernadette@ludovici.com",
    lastName: "Bernadette Ludovici",
    country: "Cameroon",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "development"
  },
  {
    id: 297,
    firstName: "interdiffusion271",
    avatar: require("../../assets/img/portrait/small/avatar-s-8.jpg"),
    email: "mui@melching.com",
    lastName: "Mui Melching",
    country: "Iran",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 298,
    firstName: "vernacularize342",
    avatar: require("../../assets/img/portrait/small/avatar-s-18.jpg"),
    email: "mitsue@houlahan.com",
    lastName: "Mitsue Houlahan",
    country: "Malawi",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 299,
    firstName: "sassenach8",
    avatar: require("../../assets/img/portrait/small/avatar-s-6.jpg"),
    email: "elsa@neubert.com",
    lastName: "Elsa Neubert",
    country: "Togo",

    role: {
      name: "client"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 300,
    firstName: "diplantidian91",
    avatar: require("../../assets/img/portrait/small/avatar-s-19.jpg"),
    email: "kandice@mizelle.com",
    lastName: "Kandice Mizelle",
    country: "Greece",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 301,
    firstName: "precognizant796",
    avatar: require("../../assets/img/portrait/small/avatar-s-22.jpg"),
    email: "damian@hayzlett.com",
    lastName: "Damian Hayzlett",
    country: "St. Lucia",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "management"
  },
  {
    id: 302,
    firstName: "submaster902",
    avatar: require("../../assets/img/portrait/small/avatar-s-16.jpg"),
    email: "aundrea@stempel.com",
    lastName: "Aundrea Stempel",
    country: "Cyprus",

    role: {
      name: "salt"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 303,
    firstName: "ladytide97",
    avatar: require("../../assets/img/portrait/small/avatar-s-21.jpg"),
    email: "shiloh@spielmaker.com",
    lastName: "Shiloh Spielmaker",
    country: "Palestine",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "development"
  },
  {
    id: 304,
    firstName: "prayingly678",
    avatar: require("../../assets/img/portrait/small/avatar-s-24.jpg"),
    email: "terese@dyreson.com",
    lastName: "Terese Dyreson",
    country: "Sao Tome and Principe",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "management"
  },
  {
    id: 305,
    firstName: "unclotted302",
    avatar: require("../../assets/img/portrait/small/avatar-s-6.jpg"),
    email: "vashti@kilton.com",
    lastName: "Vashti Kilton",
    country: "Portugal",

    role: {
      name: "client"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 306,
    firstName: "unfascinating985",
    avatar: require("../../assets/img/portrait/small/avatar-s-19.jpg"),
    email: "carter@mendes.com",
    lastName: "Carter Mendes",
    country: "Armenia",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 307,
    firstName: "gardenwise712",
    avatar: require("../../assets/img/portrait/small/avatar-s-24.jpg"),
    email: "helene@madden.com",
    lastName: "Helene Madden",
    country: "Finland",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "development"
  },
  {
    id: 308,
    firstName: "interagree870",
    avatar: require("../../assets/img/portrait/small/avatar-s-13.jpg"),
    email: "ashton@calderone.com",
    lastName: "Ashton Calderone",
    country: "Italy",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 309,
    firstName: "brutification848",
    avatar: require("../../assets/img/portrait/small/avatar-s-14.jpg"),
    email: "robert@lyster.com",
    lastName: "Robert Lyster",
    country: "Turkey",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 310,
    firstName: "unhypocritically536",
    avatar: require("../../assets/img/portrait/small/avatar-s-22.jpg"),
    email: "delma@mewbourn.com",
    lastName: "Delma Mewbourn",
    country: "Honduras",

    role: {
      name: "client"
    },
    confirmed: false,
    department: "development"
  },
  {
    id: 311,
    firstName: "tarentine951",
    avatar: require("../../assets/img/portrait/small/avatar-s-17.jpg"),
    email: "ja@alaibilla.com",
    lastName: "Ja Alaibilla",
    country: "Italy",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 312,
    firstName: "mountainlike2",
    avatar: require("../../assets/img/portrait/small/avatar-s-22.jpg"),
    email: "delinda@rosentrance.com",
    lastName: "Delinda Rosentrance",
    country: "Guinea",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "development"
  },
  {
    id: 313,
    firstName: "zoetic150",
    avatar: require("../../assets/img/portrait/small/avatar-s-18.jpg"),
    email: "danae@demeter.com",
    lastName: "Danae Demeter",
    country: "Gambia",

    role: {
      name: "client"
    },
    confirmed: true,
    department: "sales"
  },
  {
    id: 314,
    firstName: "addlepate37",
    avatar: require("../../assets/img/portrait/small/avatar-s-14.jpg"),
    email: "kattie@joffrion.com",
    lastName: "Kattie Joffrion",
    country: "Albania",

    role: {
      name: "salt"
    },
    confirmed: true,
    department: "management"
  },
  {
    id: 315,
    firstName: "pollinate51",
    avatar: require("../../assets/img/portrait/small/avatar-s-19.jpg"),
    email: "in@stjohns.com",
    lastName: "In Stjohns",
    country: "North Korea",

    role: {
      name: "freelancer"
    },
    confirmed: false,
    department: "sales"
  },
  {
    id: 316,
    firstName: "tournefortian626",
    avatar: require("../../assets/img/portrait/small/avatar-s-2.jpg"),
    email: "van@laferney.com",
    lastName: "Van Laferney",
    country: "Finland",

    role: {
      name: "freelancer"
    },
    confirmed: true,
    department: "development"
  },
  {
    id: 317,
    firstName: "aspersively497",
    avatar: require("../../assets/img/portrait/small/avatar-s-6.jpg"),
    email: "sylvia@maharrey.com",
    lastName: "Sylvia Maharrey",
    country: "Turkmenistan",

    role: {
      name: "client"
    },
    confirmed: true,
    department: "sales"
  }
]

// GET DATA
mock.onGet("/api/users/list").reply(200, users)
