// import * as firebase from "firebase/app"
import { history } from "../../../history"
import "firebase/auth"
import "firebase/database"
import api from "../../../utility/helpers/api"
import axios from "axios"
// import { config } from "../../../authServices/firebase/firebaseConfig"

// Init firebase if not already initialized
// if (!firebase.apps.length) {
//   firebase.initializeApp(config)
// }

// let firebaseAuth = firebase.auth()

// export const submitLoginWithFireBase = (email, password, remember) => {
//   return dispatch => {
//     let userEmail = null,
//       loggedIn = false
//     firebaseAuth
//       .signInWithEmailAndPassword(email, password)
//       .then(result => {
//         firebaseAuth.onAuthStateChanged(user => {
//           result.user.updateProfile({
//             displayName: "Admin"
//           })
//           let name = result.user.displayName
//           if (user) {
//             userEmail = user.email
//             loggedIn = true
//             dispatch({
//               type: "LOGIN_WITH_EMAIL",
//               payload: {
//                 email: userEmail,
//                 name,
//                 isSignedIn: loggedIn,
//                 loggedInWith: "firebase"
//               }
//             })
//           }
//           if (user && remember) {
//             firebase
//               .auth()
//               .setPersistence(firebase.auth.Auth.Persistence.SESSION)
//               .then(() => {
//                 dispatch({
//                   type: "LOGIN_WITH_EMAIL",
//                   payload: {
//                     email: userEmail,
//                     name,
//                     isSignedIn: loggedIn,
//                     remember: true,
//                     loggedInWith: "firebase"
//                   }
//                 })
//               })
//           }
//           history.push("/")
//         })
//       })
//       .catch(error => {
//         console.error(error)
//       })
//   }
// }

// export const loginWithFB = () => {
//   return dispatch => {
//     let provider = new firebase.auth.FacebookAuthProvider()
//     provider.setCustomParameters({
//       display: "popup"
//     })
//     firebaseAuth
//       .signInWithPopup(provider)
//       .then(result => {
//         // This gives you a Facebook Access Token. You can use it to access the Facebook API.
//         let token = result.credential.accessToken,
//           // The signed-in user info.
//           user = result.user.email
//         dispatch({
//           type: "LOGIN_WITH_FB",
//           payload: {
//             user,
//             token,
//             loggedInWith: "firebase"
//           }
//         })
//         if (user) history.push("/")
//       })
//       .catch(error => {
//         console.error(error)
//       })
//   }
// }

// export const loginWithTwitter = () => {
//   return dispatch => {
//     let provider = new firebase.auth.TwitterAuthProvider()
//     firebaseAuth
//       .signInWithPopup(provider)
//       .then(function(result) {
//         let token = result.credential.accessToken,
//           user = result.user.email,
//           name = result.user.displayName,
//           photoUrl = result.user.photoURL
//         dispatch({
//           type: "LOGIN_WITH_TWITTER",
//           payload: {
//             user,
//             name,
//             photoUrl,
//             token,
//             loggedInWith: "firebase"
//           }
//         })
//         history.push("/")
//       })
//       .catch(function(error) {
//         console.error(error)
//       })
//   }
// }

// export const loginWithGoogle = () => {
//   return dispatch => {
//     let provider = new firebase.auth.GoogleAuthProvider()
//     firebaseAuth
//       .signInWithPopup(provider)
//       .then(function(result) {
//         let token = result.credential.accessToken,
//           user = result.user.email,
//           name = result.user.displayName,
//           photoUrl = result.user.photoURL
//         dispatch({
//           type: "LOGIN_WITH_GOOGLE",
//           payload: {
//             email: user,
//             name: name,
//             photoUrl,
//             token,
//             loggedInWith: "firebase"
//           }
//         })
//         history.push("/")
//       })
//       .catch(function(error) {
//         console.error(error)
//       })
//   }
// }

// export const loginWithGithub = () => {
//   return dispatch => {
//     let provider = new firebase.auth.GithubAuthProvider()
//     firebaseAuth
//       .signInWithPopup(provider)
//       .then(function(result) {
//         let token = result.credential.accessToken,
//           user = result.user.email,
//           name = result.additionalUserInfo.username,
//           photoUrl = result.user.photoURL

//         dispatch({
//           type: "LOGIN_WITH_GITHUB",
//           payload: {
//             user,
//             name,
//             photoUrl,
//             token,
//             loggedInWith: "firebase"
//           }
//         })
//         history.push("/")
//       })
//       .catch(function(error) {
//         console.error(error)
//       })
//   }
// }

const loginUser = (res, dispatch) => {
  if (res.data) {
    const loggedInUser = res.data.user;
    if(res.data.user.role.id !== 1){
      localStorage.setItem("token", res.data.jwt)
      localStorage.setItem("roleId", res.data.user.role.id)

      dispatch({
        type: "LOGIN_WITH_JWT",
        payload: { ...loggedInUser, loggedInWith: "jwt" }
      })
      let url = localStorage.getItem("redirectUrl")
      url === null ? history.push("/") : history.push(url);
    }
    else{
      let text = "You are not authorized";
      dispatch({ type: 'SET_MESSAGES', payload: {text , type: 'error' } })
    }
  }
}

const errorResponse = (err, dispatch) => {
  console.error(err)
  const text = err.response.data? err.response.data.data[0].messages[0].message.replace('Identifier', 'Email') : 'Error'
  dispatch({ type: 'SET_MESSAGES', payload: { text, type: 'error' } })
}

export const loginWithJWT = user => {
  return dispatch => {
    dispatch({ type: 'SET_MESSAGES', payload: {} })
    axios.post(`${api}/auth/local`, {
      identifier: user.email,
      password: user.password
    })
    .then(res => {
       loginUser(res, dispatch)
    }).catch(err => {
      errorResponse(err, dispatch)
    })
  }
}

export const verifyJWT = () => {
  return dispatch => {
    const token = localStorage.getItem('token');

    if (token) {
      const headers = { headers: { Authorization: `Bearer ${token}` }};

      axios.get(`${api}/users/me`, headers)
      .then(res => res)
      .then(res => {
        if (res.data) {
          localStorage.setItem("id", res.data.id)

          const loggedInUser = res.data
          dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { ...loggedInUser, loggedInWith: "jwt" }
          })
        } else {
          history.push({
            pathname: '/login',
            state: { 
                from: this.props.location.pathname
            }
          })
        }
      }).catch(err => {
        console.error(err);
        localStorage.removeItem('token');
        history.push("/login");
      })
    }
  }
}

export const forgotPassword = email => {
  return dispatch => {
    dispatch({ type: 'SET_MESSAGES', payload: {} })
    axios.post(`${api}/auth/forgot-password`, { email })
    .then(res => {
      history.push("/login")
      dispatch({ type: 'SET_MESSAGES', payload: { text: 'Email code sent', type: 'ok' } })
    }).catch(err => {
      errorResponse(err, dispatch)
    })
  }
}

export const resetPassword = passwordResetOject => {
  return dispatch => {
    dispatch({ type: 'SET_MESSAGES', payload: {} })
    axios.post(
      `${api}/auth/reset-password`,
      passwordResetOject
      ).then(res => {
      loginUser(res, dispatch)
    }).catch(err => {
      errorResponse(err, dispatch)
    })
  }
}

export const logoutWithFirebase = user => {
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} })
    history.push("/login")
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}

export const logoutWithJWT = () => {
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
    localStorage.removeItem("token");
    history.push("/login")
  }
}
