import {
  ADD_QUOTIENT_FAIL,
  ADD_QUOTIENT_START,
  ADD_QUOTIENT_SUCCESS,
  GET_QUOTIENTS_FAIL,
  GET_QUOTIENTS_START,
  GET_QUOTIENTS_SUCCESS,
  GET_QUOTIENT_CHAT_FAIL,
  GET_QUOTIENT_CHAT_START,
  GET_QUOTIENT_CHAT_SUCCESS,
  GET_QUOTIENT_FAIL,
  GET_QUOTIENT_START,
  GET_QUOTIENT_SUCCESS,
  REQUEST_FINAL_QUOTE_STATUS_FAIL,
  REQUEST_FINAL_QUOTE_STATUS_START,
  REQUEST_FINAL_QUOTE_STATUS_SUCCESS,
  SAVE_QUOTIENT_CHAT_FAIL,
  SAVE_QUOTIENT_CHAT_START,
  SAVE_QUOTIENT_CHAT_SUCCESS,
  UPLOAD_PDF_FAIL,
  UPLOAD_PDF_START,
  APPROVE_PDF_FAIL,
  APPROVE_PDF_START,
  APPROVE_PDF_SUCCESS,
  MODAL_OPEN,
} from "../../actions/quotients";

const initialState = {
  quotients: [],
  listLoading: false,
  listError: undefined,
  loading: false,
  error: undefined,
  quotient: undefined,
  chatLoading: false,
  chatError: undefined,
  chats: [],
  newQuotient: undefined,
  newLoading: false,
  newError: undefined,
  finalQuoteLoading: false,
  finalQuoteError: undefined,
  approveLoad: false,
  confirmModalOpen: false,
  selectedPdfItemId: null,
  selectedPdfItemName: null
};

const quotient = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return {...state, confirmModalOpen: !action.confirmModalOpenState, selectedPdfItemId: action.selectedPdfItemId, selectedPdfItemName: action.selectedPdfItemName };

    case GET_QUOTIENTS_START:
      return { ...state, listLoading: true };
    case GET_QUOTIENTS_SUCCESS:
      return { ...state, listLoading: false, quotients: action.quotients };
    case GET_QUOTIENTS_FAIL:
      return { ...state, listLoading: false, listError: action.listError };

    case GET_QUOTIENT_START:
      return { ...state, loading: true };
    case GET_QUOTIENT_SUCCESS:
      return { ...state, loading: false, quotient: action.quotient };
    case GET_QUOTIENT_FAIL:
      return { ...state, loading: false, error: action.error };

    case GET_QUOTIENT_CHAT_START:
      return { ...state, chatLoading: true };
    case GET_QUOTIENT_CHAT_SUCCESS:
      return { ...state, chatLoading: false, chats: action.chats };
    case GET_QUOTIENT_CHAT_FAIL:
      return { ...state, chatLoading: false, chatError: action.chatError };

    case SAVE_QUOTIENT_CHAT_START:
      return { ...state, chatLoading: true };
    case SAVE_QUOTIENT_CHAT_SUCCESS:
      return { ...state, chatLoading: false, ...action.payload };
    case SAVE_QUOTIENT_CHAT_FAIL:
      return { ...state, chatLoading: false, chatError: action.chatError };

    case ADD_QUOTIENT_START:
      return { ...state, newLoading: true };
    case ADD_QUOTIENT_SUCCESS:
      return { ...state, newLoading: false, ...action.payload };
    case ADD_QUOTIENT_FAIL:
      return { ...state, newLoading: false, newError: action.newError };

    case REQUEST_FINAL_QUOTE_STATUS_START:
      return { ...state, finalQuoteLoading: true };
    case REQUEST_FINAL_QUOTE_STATUS_SUCCESS:
      return { ...state, finalQuoteLoading: false, ...action.payload };
    case REQUEST_FINAL_QUOTE_STATUS_FAIL:
      return {
        ...state,
        finalQuoteLoading: false,
        finalQuoteError: action.finalQuoteError,
      };

    case UPLOAD_PDF_START:
      return { ...state, newLoading: true };
    case UPLOAD_PDF_FAIL:
      return { ...state, newLoading: false, newError: action.newError };

    case APPROVE_PDF_START:
      return { ...state, confirmModalOpen: true, approveLoad: true };
    case APPROVE_PDF_SUCCESS:
      return { ...state, confirmModalOpen: false, approveLoad: false };
    case APPROVE_PDF_FAIL:
      return { ...state, confirmModalOpen: true, approveLoad: false, newError: action.newError };

    default:
      return state;
  }
};

export default quotient;
