import axios from "axios";
import api from "../../../utility/helpers/api";

export const GET_COMPANY_ID_SUCCESS = "GET_COMPANY_ID_SUCCESS";
export const GET_COMPANY_ID_START = "GET_COMPANY_ID_START";
export const GET_COMPANY_ID_FAIL = "GET_COMPANY_ID_FAIL";
export const GET_COMPANY_DATA_SUCCESS = "GET_COMPANY_DATA_SUCCESS";
export const GET_COMPANY_DATA_START = "GET_COMPANY_DATA_START";
export const GET_COMPANY_DATA_FAIL = "GET_COMPANY_DATA_FAIL";

export const getCompanyIdStart = () => ({
  type: GET_COMPANY_ID_START,
});
const getCompanyIDSuccess = (companyId) => ({
  type: GET_COMPANY_ID_SUCCESS,
  companyId,
});
const getCompanyIdFail = (error) => {
  return {
    type: GET_COMPANY_ID_FAIL,
    error,
  };
};
export const getCompanyId = (podId) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };

    const podUrl = `${api}/pods/${podId}`;
    await axios
      .get(podUrl, headers)
      .then((res) => {
        dispatch(getCompanyIDSuccess(res.data.company && res.data.company.id));
      })
      .catch((err) => console.error(err));
  };
};
const getCompanyDataStart = () => ({
  type: GET_COMPANY_DATA_START,
});
const getCompanyDataSuccess = (companyData) => ({
  type: GET_COMPANY_DATA_SUCCESS,
  companyData,
});

const getCompanyDataFail = (error) => ({
  type: GET_COMPANY_DATA_FAIL,
  error,
});
export const getCompanyData = (skilledId) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    dispatch(getCompanyDataStart());
    await axios
      .get(`${api}/getSkillsData/${skilledId}/`, headers)
      .then((response) => {
        dispatch(getCompanyDataSuccess(response.data));
      })
      .catch((err) => console.error(err));
  };
};

export const getPod = (id, startLoading = true) => {
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${api}/pods/${id}`;

  return async (dispatch) => {
    startLoading &&
      dispatch({
        type: "GET_POD_LOADING",
      });
    await axios
      .get(url, { headers })
      .then((res) => {
        dispatch({
          type: "GET_POD",
          pod: res.data,
        });
      })
      .catch((err) =>
        dispatch({
          type: "GET_POD_ERROR",
        })
      );
  };
};

export const updatePod = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_POD",
      payload,
    });
  };
};

export const addMobbers = (id, mobbers) => {
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${api}/pods/${id}`;

  return async (dispatch) => {
    // dispatch({
    //   type: "ADD_MOBBERS_LOADING",
    // });
    await axios
      .put(url, { mobbers }, { headers })
      .then((res) => {
        dispatch({
          type: "ADD_MOBBERS_DATA",
          mobbers: mobbers,
        });
      })
      .catch((err) =>
        dispatch({
          type: "ADD_MOBBERS_ERROR",
        })
      );
  };
};
