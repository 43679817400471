import { combineReducers } from "redux";
import calenderReducer from "./calendar/";
import emailReducer from "./email/";
import chatReducer from "./chat/";
import todoReducer from "./todo/";
import customizer from "./customizer/";
import auth from "./auth/";
import messages from "./messages/";
import navbar from "./navbar/Index";
import dataList from "./data-list/";
import pod from "./pod";
import kanban from "./kanban";
import quotient from "./quotients";

const rootReducer = combineReducers({
  calendar: calenderReducer,
  emailApp: emailReducer,
  todoApp: todoReducer,
  chatApp: chatReducer,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  messages: messages,
  pod: pod,
  kanban: kanban,
  quotient: quotient,
});

export default rootReducer;
